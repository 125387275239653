/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Paper,
  Divider,
  IconButton,
  Button,
} from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  myOrders,
  placeOrder,
  CALCULATE_GOOGLE_MAPS_DISTANCE,
} from '../../apollo/server';
import CodIcon from '../../assets/icons/CodIcon';
import RiderImage from '../../assets/images/rider.png';
import MarkerImage from '../../assets/images/marker.png';
import {
  CartItemCard,
  DeliveryCard,
  PaymentCard,
  PersonalCard,
  OrderOption,
} from '../../components/Checkout';
import CloseIcon from '@mui/icons-material/Close';
import { calculateGoogleMapsDistance } from '../../utils/google-maps';
import FlashMessage from '../../components/FlashMessage';
import Footer from '../../components/HomeScreen/Footer';
import { Header } from '../../components/Header';
import { RestaurantClose } from '../../components/Modals';
import ConfigurationContext from '../../context/Configuration';
import { useLocationContext } from '../../context/Location';
import UserContext from '../../context/User';
import { useRestaurant } from '../../hooks';
import { DAYS } from '../../utils/constantValues';
import { paypalCurrencies, stripeCurrencies } from '../../utils/currencies';
import {
  calculateAmount,
  calculateDistance,
  checkAlwaysPreOrder,
  checkHoliday,
  checkPreorder,
  filterSlotsByDay,
  findNextOpenDate,
  getCurrentDay,
  getCurrentDaySlotName,
  getDeliverySlotsForDate,
} from '../../utils/customFunction';
import useStyle from './styles';

import Analytics from '../../utils/analytics';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { mapStyles } from '../OrderDetail/mapStyles';
import RestMarker from '../../assets/images/rest-map-2.png';
import NearMeIcon from '@mui/icons-material/NearMe';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';

import moment from 'moment';
import DeliverySlotModal from './DeliverySlotModal';
import PreOrderModal from './PreOrderSchedule';

const PLACEORDER = gql`
  ${placeOrder}
`;
const ORDERS = gql`
  ${myOrders}
`;

const PAYMENT = {
  id: 2,
  payment: 'COD',
  label: 'Cash',
  icon: <CodIcon />,
};

function Checkout() {
  const { t } = useTranslation();
  const classes = useStyle();
  const navigate = useNavigate();
  const [isClose, setIsClose] = useState(false);
  const [mainError, setMainError] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const configuration = useContext(ConfigurationContext);
  const [addressModal, setAddressModal] = useState(false);
  const [orderOptionModal, setOrderOptionModal] = useState(false);
  const [loadingLocation, setLoadingLocation] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const {
    profile,
    clearCart,
    restaurant: cartRestaurant,
    cart,
    cartCount,
    addQuantity,
    removeQuantity,
    fetchPreOrders,
    isPickup,
    setIsPickup,
  } = useContext(UserContext);

  const { location, setLocation } = useLocationContext();
  const theme = useTheme();
  const [minimumOrder, setMinimumOrder] = useState('');
  const [selectedTip, setSelectedTip] = useState();
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT);
  const [taxValue, setTaxValue] = useState();

  const [coupon, setCoupon] = useState({});
  const [selectedDate, handleDateChange] = useState(new Date());
  const [isPickUp, setIsPickUp] = useState(false);
  const [deliveryCharges, setDeliveryCharges] = useState(0);

  let restCoordinates = {};
  const { loading, data, error, plan } = useRestaurant(cartRestaurant);
  const extraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [calculateDistanceBetweenTwoPoints] = useLazyQuery(
    CALCULATE_GOOGLE_MAPS_DISTANCE
  );
  const [mutateOrder, { loading: loadingOrderMutation }] = useMutation(
    PLACEORDER,
    {
      onCompleted,
      onError,
      update,
    }
  );

  // Pre-Order
  const preOrder = checkPreorder(data?.restaurant);
  const preOrder2 = checkAlwaysPreOrder(data?.restaurant);
  const [isPreOrder, setIsPreOrder] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [sbmtBy, setSbmtBy] = useState('Normal');

  // Delivery Slot
  const [open, setOpen] = useState(false);
  const allowedConfigs = data?.restaurant?.deliveryMode?.deliveryOption;
  const restaurantDeliverySlots = data?.restaurant?.deliverySlots;
  let { isHoliday } = checkHoliday(data?.restaurant?.holidays);
  const [filteredSlots, setFilteredSlots] = useState([]);
  const [showName, setShowName] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedSlotDay, setSelectedSlotDay] = useState(null);
  const [extraOwnerFee, setExtraOwnerFee] = useState({
    delivery: 0,
    commission: 0,
  });

  const latOrigin = data?.restaurant?.location?.coordinates[1];
  const lonOrigin = data?.restaurant?.location?.coordinates[0];

  const onLoad = useCallback(
    (map) => {
      const bounds = new window.google.maps.LatLngBounds();
      map.panToBounds(bounds);
    },
    [restCoordinates]
  );

  const isOpenCheck = useCallback(() => {
    let check = { isOpen: false, nextOpenDate: null };

    if (data?.restaurant?.openingTimes?.length < 1) return check;

    const date = new Date();
    const day = date.getDay();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const todaysTimings = data?.restaurant?.openingTimes?.find(
      (o) => o.day === DAYS[day]
    );

    if (todaysTimings === undefined) return check;

    const times = todaysTimings?.times?.filter(
      (t) =>
        hours >= Number(t.startTime[0]) &&
        minutes >= Number(t.startTime[1]) &&
        hours <= Number(t.endTime[0]) &&
        minutes <= Number(t.endTime[1])
    );

    if (times.length > 0) {
      check = { isOpen: true, nextOpenDate: null };
    } else {
      if (data?.restaurant?.deliveryMode?.allowPreOrd) {
        check = {
          isOpen: false,
          nextOpenDate: findNextOpenDate(
            data?.restaurant?.openingTimes,
            day,
            date
          ),
        };
      }
    }

    return check;
  }, [data]);

  const toggleAdressModal = useCallback(() => {
    setAddressModal((prev) => !prev);
  }, []);

  const setDeliveryAddress = (item) => {
    setSelectedAddress(item);
    setLocation({
      _id: item?._id,
      label: item?.label,
      latitude: Number(item?.location.coordinates[1]),
      longitude: Number(item?.location.coordinates[0]),
      deliveryAddress: item?.deliveryAddress,
      details: item?.details,
    });
  };

  const toggleCloseModal = useCallback(() => {
    setIsClose((prev) => !prev);
  }, []);

  const restaurantData = data?.restaurant ?? null;

  const showMessage = useCallback((messageObj) => {
    setMainError(messageObj);
  }, []);

  const toggleSnackbar = useCallback(() => {
    setMainError({});
  }, []);

  useEffect(() => {
    if (cart && cartCount > 0) {
      if (data && data.restaurant && !data.restaurant.isAvailable) {
        setIsClose((prev) => {
          if (!prev) return true;
          else return prev;
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (allowedConfigs?.includes('Delivery')) {
      setIsPickup(false);
    } else {
      setIsPickup(true);
    }
  }, [allowedConfigs]);

  // Delivery Slot Effects
  useEffect(() => {
    if (preOrder?.canPreOrder) {
      // if store is closed and nextOpenDate is available
      if (
        isOpenCheck().isOpen === false &&
        isOpenCheck().nextOpenDate !== null
      ) {
        const check = getDeliverySlotsForDate(
          isOpenCheck()?.nextOpenDate,
          restaurantDeliverySlots
        );
        setShowName(check.s);
        setSelectedSlotDay(check.day);
        setFilteredSlots(check.slots);
        return;
      }
      // if store is on holiday set order date on max fays and hours
      const check = getDeliverySlotsForDate(
        preOrder?.timeToOrder,
        restaurantDeliverySlots
      );
      setShowName(check.s);
      setSelectedSlotDay(check.day);
      setFilteredSlots(check.slots);
    } else {
      const day = getCurrentDay();
      setSelectedSlotDay(day);
      let n = getCurrentDaySlotName();
      setShowName(n);
      const slotsForToday = filterSlotsByDay(restaurantDeliverySlots, day);
      setFilteredSlots(slotsForToday);
    }
  }, [restaurantDeliverySlots]);

  useEffect(() => {
    if (!location) {
      let localStorageLocation = localStorage.getItem('location');
      localStorageLocation = JSON.parse(localStorageLocation);
      if (localStorageLocation) {
        setLocation(localStorageLocation);
      }
    }
  }, []);

  useEffect(() => {
    calculateDeliveryAndCommissionFrom();
  }, [plan, data?.restaurant]);

  useEffect(() => {
    let isSubscribed = true;
    (async () => {
      if (data && !!data.restaurant) {
        const latOrigin = Number(data.restaurant.location.coordinates[1]);
        const lonOrigin = Number(data.restaurant.location.coordinates[0]);
        const latDest = Number(location.latitude);
        const longDest = Number(location.longitude);
        const _key = `${latOrigin}-${lonOrigin}-${latDest}-${longDest}`;
        let distance = -1;
        const stored_distance = JSON.parse(localStorage.getItem(_key) ?? '-1');

        if (!stored_distance || typeof stored_distance !== 'number') {
          const { data: distanceData } =
            await calculateDistanceBetweenTwoPoints({
              variables: {
                location: {
                  source: { lat: latOrigin, lng: lonOrigin },
                  destination: { lat: latDest, lng: longDest },
                },
              },
            });

          const distanceInMeters =
            distanceData?.calculateDistanceBetweenTwoPoints;
          distance = distanceInMeters / 1000.0;

          localStorage.setItem(
            _key,
            String(distanceData?.calculateDistanceBetweenTwoPoints)
          );
        } else {
          distance = stored_distance / 1000.0;
        }

        let costType = configuration.costType;
        let amount = calculateAmount(
          costType,
          configuration.deliveryRate,
          distance
        );
        // const amount = Math.ceil(distance) * configuration.deliveryRate;
        // setDeliveryCharges(amount > 0 ? amount : configuration.deliveryRate);

        if (isSubscribed) {
          const hasOwnDrivers = data?.restaurant.drivers?.length > 0;
          if (hasOwnDrivers) {
            setDeliveryCharges(extraOwnerFee.delivery);
          } else {
            setDeliveryCharges(
              amount > 0 ? amount : configuration.deliveryRate
            );
          }
        }
      }
    })();
  }, [data, location, extraOwnerFee.delivery]);

  if (loading || loadingData) {
    return (
      <Grid container>
        <Header />
        <Box className={classes.spinnerContainer}>
          <CircularProgress color="primary" size={48} />
        </Box>
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container>
        <Header />
        <Box className={classes.spinnerContainer}>
          <Typography>Unable to fetch data</Typography>
        </Box>
      </Grid>
    );
  }

  restCoordinates = {
    lat: parseFloat(data.restaurant.location.coordinates[1]),
    lng: parseFloat(data.restaurant.location.coordinates[0]),
  };

  function update(cache, { data: { placeOrder } }) {
    if (placeOrder && placeOrder.paymentMethod === 'COD') {
      const data = cache.readQuery({ query: ORDERS });
      if (data) {
        cache.writeQuery({
          query: ORDERS,
          data: { orders: [placeOrder, ...data.orders] },
        });
      }
    }
  }

  function onError(error) {
    console.log('Check-out Error', error);
    showMessage({
      type: 'error',
      message: error.message,
    });
  }

  // Price Calculation Handlers
  async function calculateDeliveryAndCommissionFrom() {
    try {
      if (Object.keys(plan) <= 0) return;
      if (!data?.restaurant) return;

      const _restaurant = data?.restaurant;
      const hasOwnDrivers = _restaurant?.drivers?.length > 0;

      // Adding Delivery and Order Commission to Line Items
      const extra_owner_fee = { delivery: 0, commission: 0 };
      const extra_line_items = ['delivery'];
      for (const extraItem of extra_line_items) {
        const plan_item_type = plan[extraItem];

        switch (extraItem) {
          case 'delivery':
            if (isPickup) break;
            if (!hasOwnDrivers) break; //Ony add this delivery fee if restaurant is using its own drivers.
            // Only Percentage For Now
            // if (plan_item_type.price_type === 'percentage') {
            let deliveryFee = _restaurant.drivers.length
              ? _restaurant.deliveryInfo.minDeliveryFee
              : configuration.deliveryRate;
            try {
              const latDest = Number(location.latitude);
              const longDest = Number(location.longitude);

              const _key = `${latOrigin}-${lonOrigin}-${latDest}-${longDest}`;
              let distanceInMetres = -1;
              const stored_distance = JSON.parse(
                localStorage.getItem(_key) ?? '-1'
              );

              if (!stored_distance || typeof stored_distance !== 'number') {
                const { data: distanceData } =
                  await calculateDistanceBetweenTwoPoints({
                    variables: {
                      location: {
                        source: {
                          lat: Number(latOrigin),
                          lng: Number(lonOrigin),
                        },
                        destination: { lat: latDest, lng: longDest },
                      },
                    },
                  });

                distanceInMetres =
                  distanceData?.calculateDistanceBetweenTwoPoints;

                localStorage.setItem(
                  _key,
                  String(distanceData?.calculateDistanceBetweenTwoPoints)
                );
              } else {
                distanceInMetres = stored_distance;
              }

              const distanceInKM = distanceInMetres / 1000;

              if (distanceInKM > _restaurant.deliveryInfo.deliveryDistance) {
                const diffDistance = Math.abs(
                  distanceInKM - _restaurant.deliveryInfo.deliveryDistance
                );

                deliveryFee +=
                  diffDistance * _restaurant.deliveryInfo.deliveryFee;
              }

              extra_owner_fee['delivery'] = deliveryFee;
              // Add delivery fee based on distance if needed
            } catch (distanceError) {
              console.log({ distanceError });
              extra_owner_fee['delivery'] = deliveryFee;
            }
            break;
          default:
            console.log('Invalid type');
        }
      }

      setExtraOwnerFee(extra_owner_fee);
    } catch (err) {
      console.log({ err });
      FlashMessage({
        message: `Order Commission and Delivery Fee could not be calculated. Please refresh.`,
        // message: `(${t(minAmount)}) (${configuration.currencySymbol
        //   } ${minimumOrder}) (${t(forYourOrder)})`
      });
    }
  }

  function calculateTip() {
    if (selectedTip) {
      let total = 0;
      const delivery = isPickUp ? 0 : deliveryCharges;
      total += +calculatePrice(delivery, true);
      total += +taxCalculation();
      const tipPercentage = (
        (total / 100) *
        parseFloat(selectedTip).toFixed(2)
      ).toFixed(2);
      return tipPercentage;
    } else {
      return 0;
    }
  }

  function taxCalculation() {
    // const tax = taxValue ?? 0;
    // if (tax === 0) {
    //   return tax.toFixed(2);
    // }
    // const delivery = isPickUp ? 0 : deliveryCharges;
    // const amount = +calculatePrice(delivery, true);
    // const taxAmount = ((amount / 100) * tax).toFixed(2);
    // return taxAmount;

    const tax = data.restaurant ? Number(data.restaurant.tax) : 0;
    if (tax === 0) {
      return Number(parseInt(tax).toFixed(2));
    }
    const delivery = Boolean(isPickup) ? 0 : Number(deliveryCharges);
    const amount = calculatePrice(delivery, true);
    const taxAmount = Number((amount / 100) * tax.toFixed(2));
    return taxAmount;
  }
  function calculatePrice(delivery = 0, withDiscount) {
    let itemTotal = 0;
    let isDeliveryDiscount = false;

    cart.forEach((cartItem) => {
      itemTotal += cartItem.price * cartItem.quantity;
    });

    // Order Discount
    if (withDiscount && coupon) {
      switch (coupon.discountType) {
        case 'FIXED':
          itemTotal = itemTotal - coupon.promotion.fixed;
          break;
        case 'PERCENTAGE':
          itemTotal =
            itemTotal - (coupon.promotion.percentage / 100) * itemTotal;
          break;
        default:
          isDeliveryDiscount = true;
          break;
      }
    }

    // Delivery Discount
    // const deliveryAmount = delivery > 0 ? deliveryCharges : 0;
    // return Number((itemTotal + deliveryAmount).toFixed(2));
    const deliveryAmount = isDeliveryDiscount
      ? 0
      : delivery > 0
        ? deliveryCharges
        : 0;
    return Number((itemTotal + deliveryAmount).toFixed(2));
  }

  // CHeck
  function calculateTotal() {
    let total = 0;
    const delivery = isPickUp ? 0 : deliveryCharges;
    total += +calculatePrice(delivery, true);
    total += +taxCalculation();
    total += +calculateTip();

    total += +(configuration?.platformFee || 0);
    return parseFloat(total).toFixed(2);
  }

  async function onCompleted(data) {
    await Analytics.track(Analytics.events.ORDER_PLACED, {
      userId: data.placeOrder.user._id,
      name: data.placeOrder.user.name,
      email: data.placeOrder.user.email,
      phoneNumber: data.placeOrder.user.phone,
      orderId: data.placeOrder.orderId,
      restaurantName: data.placeOrder.restaurant.name,
      restaurantAddress: data.placeOrder.restaurant.address,
      orderItems: data.placeOrder.items,
      orderPaymentMethod: data.placeOrder.paymentMethod,
      orderAmount: data.placeOrder.orderAmount,
      orderPaidAmount: data.placeOrder.paidAmount,
      tipping: data.placeOrder.tipping,
      orderStatus: data.placeOrder.orderStatus,
      orderDate: data.placeOrder.orderDate,
    });
    if (paymentMethod.payment === 'COD') {
      await clearCart();
      setOpen(false);
      fetchPreOrders();
      navigate(`/order-detail/${data.placeOrder._id}`, {
        replace: true,
        state: { preOrder: preOrder.canPreOrder | isPreOrder ? true : false },
      });
    }
    if (paymentMethod.payment === 'STRIPE') {
      navigate(`/stripe?id=${data.placeOrder._id}`, { replace: true });
    }
  }

  function transformOrder(cartData) {
    return cartData.map((food) => {
      return {
        food: food._id,
        quantity: food.quantity,
        variation: food.variation._id,
        addons: food.addons
          ? food.addons.map(({ _id, options }) => ({
              _id,
              options: options.map(({ _id }) => _id),
            }))
          : [],
        specialInstructions: food.specialInstructions,
      };
    });
  }

  function checkPaymentMethod(currency) {
    if (paymentMethod.payment === 'STRIPE') {
      return stripeCurrencies.find((val) => val.currency === currency);
    }
    if (paymentMethod.payment === 'PAYPAL') {
      return paypalCurrencies.find((val) => val.currency === currency);
    }
    return true;
  }

  async function onPayment(normal = true, values = {}) {
    if (checkPaymentMethod(configuration.currency)) {
      const items = transformOrder(cart);

      const orderVariables = {
        restaurant: cartRestaurant,
        orderInput: items,
        paymentMethod: paymentMethod.payment,
        couponCode: coupon ? coupon.title : null,
        tipping: +calculateTip(),
        taxationAmount: +taxCalculation(),
        address: {
          label: 'Home',
          deliveryAddress: location.deliveryAddress,
          details: location.deliveryAddress,
          longitude: '' + location.longitude,
          latitude: '' + location.latitude,
        },
        orderDate: selectedDate,

        // normally no pre-order
        preOrder: {
          canPreOrder: false,
          timeToOrder: null,
        },
        isPickedUp: isPickUp,
        deliveryCharges: isPickUp ? 0 : deliveryCharges,
      };

      if (!isPickUp) {
        let tempSlot;

        if (!normal) {
          // if it is always
          tempSlot = { ...values.deliverySlot };
        } else {
          // if it is on holiday
          tempSlot = { ...selectedSlot, day: selectedSlotDay };
          delete tempSlot.__typename;
          delete tempSlot.orderCapacity;
          delete tempSlot.orderPlaced;
        }
        orderVariables.deliverySlot = tempSlot;
      } else {
        orderVariables.address = {
          label: 'Current Location',
          deliveryAddress: 'Pickup',
          details: 'User will pick up the order',
          longitude: '' + profile.longitude,
          latitude: '' + profile.latitude,
        };
      }

      // if pre-order allow in holiday
      if (preOrder.canPreOrder) {
        // for store closed
        if (
          isOpenCheck().nextOpenDate !== null &&
          isOpenCheck().isOpen === false
        ) {
          orderVariables.preOrder = {
            canPreOrder: true,
            timeToOrder: isOpenCheck().nextOpenDate,
          };
        } else {
          // for store closed
          orderVariables.preOrder = preOrder;
        }
      }

      // if always
      if (!normal) {
        orderVariables.preOrder = values.preOrder;
      }

      mutateOrder({ variables: orderVariables });
    } else {
      showMessage({
        type: 'warning',
        message: 'Payment not supported',
      });
    }
  }

  const locationCallback = (error, data) => {
    setLoadingLocation(false); // Stop loading
    if (error) {
      console.error(error);
      return;
    }

    // Create an object for the selected address using the current location data
    const selectedAddress = {
      label: 'Your Location',
      deliveryAddress: data.label,
      details: data.details,
      latitude: data.coords.latitude,
      longitude: data.coords.longitude,
    };

    // Update the selected address in state
    setSelectedAddress(selectedAddress);
    setAddressModal((prev) => !prev);
  };

  const getCurrentLocation = () => {
    setLoadingLocation(true); // Start loading (optional)

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          locationCallback(null, position);
        },
        (error) => {
          locationCallback(error, null);
        }
      );
    } else {
      // Geolocation is not supported by the browser
      locationCallback(new Error('Geolocation is not supported.'), null);
    }
  };

  function validateOrder() {
    // if store is closed and pre-order on holiday is allowed
    if (isOpenCheck().isOpen === false && isOpenCheck().nextOpenDate === null) {
      toggleCloseModal();
      return;
    }
    // if store is on holiday and preorder is false
    if (isHoliday === true && preOrder.canPreOrder === false) {
      toggleCloseModal();
      return;
    }
    // if store is not available
    if (!data.restaurant.isAvailable) {
      toggleCloseModal();
      return;
    }
    if (!cart.length) {
      showMessage({
        type: 'error',
        message: 'Cart is Empty.',
      });
      return false;
    }
    const delivery = isPickUp ? 0 : deliveryCharges;
    if (calculatePrice(delivery, true) < minimumOrder) {
      showMessage({
        type: 'warning',
        message: `The minimum amount of (${configuration.currencySymbol} ${minimumOrder}) for your order has not been reached.`,
      });
      return false;
    }
    if (!location) {
      showMessage({
        alive: true,
        type: 'Warning',
        message: 'Select your address.',
      });
      return false;
    }
    if (!paymentMethod) {
      showMessage({
        type: 'warning',
        message: 'Set payment method before checkout',
      });
      return false;
    }
    if (!profile.addresses || !profile.name || !profile.email) {
      showMessage({
        alive: true,
        type: 'Error',
        message: t('ProfileDataMissing'),
      });

      setTimeout(() => {
        navigate('/profile');
      }, 1000);

      return false;
    }
    if (!profile.phone) {
      showMessage({
        alive: true,
        type: 'Error',
        message: t('phoneNumMissing'),
      });

      setTimeout(() => {
        navigate('/phone-number');
      }, 1000);

      return false;
    }
    if (!profile.phoneIsVerified) {
      showMessage({
        alive: true,
        type: 'Error',
        message: 'Phone Number is not verified',
      });

      setTimeout(() => {
        navigate('/phone-number');
      }, 1000);

      return false;
    }
    return true;
  }

  const processOrder = (normal = true, values = {}) => {
    if (validateOrder()) onPayment(normal, values);
  };

  return (
    <>
      <Grid container className={classes.root}>
        <FlashMessage
          open={Boolean(mainError.type)}
          severity={mainError.type}
          alertMessage={mainError.message}
          handleClose={toggleSnackbar}
          alive={mainError.alive || false}
        />

        <Header />
        <Grid
          container
          item
          className={classes.mainContainer}
          justifyContent="center"
        >
          <Grid container item>
            <Grid item xs={12} className={classes.topContainer}>
              <GoogleMap
                mapContainerStyle={{
                  height: '450px',
                  width: '100%',
                }}
                zoom={14}
                center={restCoordinates}
                onLoad={restCoordinates && onLoad}
                options={{
                  styles: mapStyles,
                  zoomControl: true,
                  zoomControlOptions: {
                    position: window.google.maps.ControlPosition.RIGHT_CENTER,
                  },
                }}
              >
                {location && (
                  <Marker
                    position={{
                      lat: location?.latitude,
                      lng: location?.longitude,
                    }}
                    icon={MarkerImage}
                  />
                )}
                <Marker position={restCoordinates} icon={RestMarker} />
              </GoogleMap>
            </Grid>
          </Grid>
          <Container maxWidth="md" className={classes.containerCard}>
            <Box
              className={classes.headerBar}
              display="flex"
              alignItems={'center'}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <img src={RiderImage} alt="rider" />
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                style={{
                  marginLeft: '20px',
                }}
              >
                <Typography
                  style={{
                    ...theme.typography.body1,
                    color: theme.palette.common.black,
                    fontSize: '1.275rem',
                    fontWeight: 600,
                  }}
                >
                  {t('deliveryTime')}
                </Typography>
                <Typography
                  style={{
                    ...theme.typography.body1,
                    color: theme.palette.grey[600],
                    fontSize: '0.775rem',
                    fontWeight: 600,
                  }}
                >
                  {moment().format('DD-MM-YYYY')} | {moment().format('LT')}
                </Typography>
                <Box display="flex" mt={2} alignItems="center">
                  <Typography
                    style={{
                      ...theme.typography.body1,
                      color: theme.palette.common.white,
                      fontSize: '0.875rem',
                      fontWeight: 600,
                    }}
                  >
                    {isPickUp ? t('pickUp') : t('delivery')}
                  </Typography>
                  <Button
                    variant="contained"
                    style={{
                      marginLeft: theme.spacing(1),
                      backgroundColor: 'black',
                      borderRadius: theme.spacing(1.5),
                    }}
                    onClick={() => setOrderOptionModal((prev) => !prev)}
                  >
                    <Typography
                      style={{
                        color: theme.palette.common.white,
                        fontSize: '0.775rem',
                        fontWeight: 600,
                        textTransform: 'capitalize',
                      }}
                    >
                      {t('change')}
                    </Typography>
                  </Button>
                  <OrderOption
                    selectedDate={selectedDate}
                    handleDateChange={handleDateChange}
                    setIsPickUp={setIsPickUp}
                    orderOptionModal={orderOptionModal}
                    setOrderOptionModal={setOrderOptionModal}
                    isPickUp={isPickUp}
                  />
                </Box>
              </Box>
            </Box>
            <Grid container spacing={2} sx={{ mt: 2, mb: 25 }}>
              {/* voucher */}
              <Grid item xs={12} sm={6}>
                <CartItemCard
                  setSelectedTip={setSelectedTip}
                  selectedTip={selectedTip}
                  setTaxValue={setTaxValue}
                  setCoupon={setCoupon}
                  restaurantData={restaurantData}
                  setFlashMessage={showMessage}
                  calculateTotal={calculateTotal}
                  calculatePrice={calculatePrice}
                  taxCalculation={taxCalculation}
                  calculateTip={calculateTip}
                  isPickUp={isPickUp}
                  deliveryCharges={deliveryCharges}
                  coupon={coupon}
                  addQuantity={addQuantity}
                  removeQuantity={removeQuantity}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {!isPickUp && (
                  <Dialog
                    fullScreen={extraSmall}
                    onClose={toggleAdressModal}
                    open={addressModal}
                    maxWidth="md"
                    PaperProps={{
                      style: {
                        borderRadius: 30,
                        overflowY: 'scroll',
                        height: extraSmall ? 500 : null,
                      },
                    }}
                  >
                    <>
                      <Box display="flex" justifyContent="flex-end">
                        <IconButton
                          size={extraSmall ? 'medium' : 'small'}
                          onClick={toggleAdressModal}
                          className={classes.closeContainer}
                        >
                          <CloseIcon color="primary" />
                        </IconButton>
                      </Box>
                      <Box style={{ width: '90%', margin: `16px auto` }}>
                        <Box display="flex">
                          <Typography
                            style={{ color: theme.palette.primary.main }}
                            variant="caption"
                            fontWeight={800}
                          >
                            {t('deliverTo')}:
                          </Typography>
                          <Typography
                            style={{
                              color: theme.palette.common.black,
                              marginLeft: 10,
                            }}
                            variant="caption"
                            fontWeight={800}
                          >
                            {location?.label}
                          </Typography>
                        </Box>
                        <Typography
                          style={{ color: theme.palette.grey[600] }}
                          variant="caption"
                          fontWeight={600}
                        >
                          {location?.deliveryAddress}
                        </Typography>
                      </Box>

                      <Grid
                        item
                        xs={12}
                        justifyContent="center"
                        style={{
                          background: theme.palette.common.white,
                          padding: theme.spacing(2, 0),
                          marginLeft: '20px',
                        }}
                      >
                        <Paper
                          className={classes.deliveryPaperProfile}
                          style={{ width: '90%' }}
                        >
                          <Box
                            className={clsx(
                              classes.PH1,
                              classes.PB2,
                              classes.PT2
                            )}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            onClick={(e) => {
                              e.preventDefault();
                              getCurrentLocation();
                            }}
                          >
                            <Box display="flex" alignItems="center">
                              <NearMeIcon
                                width={100}
                                height={100}
                                style={{ color: theme.palette.common.black }}
                              />
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                align="left"
                                className={clsx(classes.smallText, classes.PH1)}
                                fontWeight={600}
                              >
                                {t('currentLocation')}
                              </Typography>
                            </Box>
                            {loadingLocation && (
                              <CircularProgress color={'warning'} />
                            )}
                          </Box>
                        </Paper>
                      </Grid>
                      <Divider
                        orientation="horizontal"
                        className={classes.divider}
                      />
                      <DeliveryCard
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setDeliveryAddress}
                        isProfile={true}
                        isCheckout={true}
                        close={toggleAdressModal}
                      />
                    </>
                  </Dialog>
                )}

                <PersonalCard
                  toggleModal={toggleAdressModal}
                  location={location}
                />
                <PaymentCard
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                  handleOrder={() => {
                    if (!isPickUp) {
                      if (selectedSlot !== null) {
                        setSbmtBy('Normal');
                        processOrder();
                      } else {
                        setOpen(true);
                      }
                    } else {
                      setSbmtBy('Normal');
                      processOrder();
                    }
                  }}
                  handleAlwaysPreOrder={() => {
                    setOpen2(true);
                  }}
                  sbmtBy={sbmtBy}
                  onPayment={onPayment}
                  loading={loadingOrderMutation}
                  calculateTotal={calculateTotal}
                  location={location}
                  preOrder={preOrder}
                  preOrder2={preOrder2}
                />
              </Grid>
            </Grid>
          </Container>
        </Grid>

        <RestaurantClose
          isVisible={isClose}
          toggleModal={toggleCloseModal}
          restaurant={restaurantData.name}
          isClose={isClose}
          isHoliday={isHoliday}
          isOpenCheck={isOpenCheck()}
        />

        <DeliverySlotModal
          open={open}
          onClose={() => {
            setOpen(false);
            setSelectedSlot(null);
          }}
          pre={preOrder}
          filteredSlots={filteredSlots}
          selectedSlot={selectedSlot}
          setSelectedSlot={setSelectedSlot}
          showName={showName}
          handleSlotSelection={(slot) => {
            setSelectedSlot(JSON.parse(slot));
          }}
          handleNext={() => {
            setOpen(false);
          }}
        />

        <PreOrderModal
          pre2={preOrder2}
          open={open2}
          onClose={() => {
            setOpen2(false);
          }}
          deliverySlots={restaurantDeliverySlots}
          handlePreOrder={(d) => {
            setIsPreOrder(true);
            setSbmtBy('Date');
            processOrder(false, d);
          }}
          loading={loadingOrderMutation}
        />
      </Grid>
      <Footer />
    </>
  );
}

export default Checkout;
