import { useEffect } from 'react';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { restaurant, getPlanById } from '../apollo/server';

const RESTAURANT = gql`
  ${restaurant}
`;

const PLAN_BY_ID = gql`
  ${getPlanById}
`;

export default function useRestaurant(id, slug) {
  const { data, refetch, networkStatus, loading, error } = useQuery(
    RESTAURANT,
    {
      variables: { id, slug },
      fetchPolicy: 'network-only',
    }
  );

  const [fetchPlanById, { data: planData, loading: isPlanFetching }] =
    useLazyQuery(PLAN_BY_ID);

  // Handler
  const onFetchPlanById = async () => {
    try {
      const planId = data?.restaurant?.subscriptionPlan?.planId ?? '';

      if (!planId) return;

      await fetchPlanById({
        variables: {
          id: planId,
        },
        fetchPolicy: 'network-only',
      });
    } catch (error) {}
  };

  useEffect(() => {
    onFetchPlanById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.restaurant?.subscriptionPlan]);

  return {
    data,
    refetch,
    networkStatus,
    loading,
    error,
    plan: planData?.getPlanById || {},
    isPlanFetching,
  };
}
