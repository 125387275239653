
const ACTIVE_STATUS = ["PENDING", "PICKED", "ACCEPTED", "ASSIGNED"];
const INACTIVE_STATUS = ["DELIVERED", "COMPLETED", "CANCELLED"];
const ORDER_STATUS = ["PENDING", "ACCEPTED"];
const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const DAYS_SLOTS = [
  'Sunday Slots',
  'Monday Slots',
  'Tuesday Slots',
  'Wednesday Slots',
  'Thursday Slots',
  'Friday Slots',
  'Saturday Slots',
];

const DAYSPREFIX = [
  'SUN',
  'MON-FRI',
  'MON-FRI',
  'MON-FRI',
  'MON-FRI',
  'MON-FRI',
  'SAT',
];



export { ACTIVE_STATUS, INACTIVE_STATUS, ORDER_STATUS, DAYS,DAYSPREFIX,DAYS_SLOTS };
