import { DAYS, DAYS_SLOTS, DAYSPREFIX } from './constantValues';

function isValidEmailAddress(address) {
  return !!address.match(/.+@.+/);
}

const cartItemQuantity = (items) => {
  return items
    .map(
      (item) =>
        `${item.quantity}x ${item.title}${
          item.variation.title ? `(${item.variation.title})` : ''
        }`
    )
    .join('\n');
};

function calculatePrice(food) {
  var foodPrice = food.variation.price;
  food.addons.forEach((addons) => {
    addons.options.forEach((addon) => {
      foodPrice += addon.price;
    });
  });
  return foodPrice;
}

function calculateDistance(latS, lonS, latD, lonD) {
  var R = 6371; // km
  var dLat = toRad(latD - latS);
  var dLon = toRad(lonD - lonS);
  var lat1 = toRad(latS);
  var lat2 = toRad(latD);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
}

async function calculateGoogleMapsDistance(apiKey, origin, destination) {
  const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.rows[0].elements[0].status === 'OK') {
      const distance = data.rows[0].elements[0].distance.value;
      return distance;
    } else {
      throw new Error(`Error: ${data.rows[0].elements[0].status}`);
    }
  } catch (error) {
    throw new Error(`Error: ${error.message}`);
  }
}

// Converts numeric degrees to radians
function toRad(Value) {
  return (Value * Math.PI) / 180;
}

const isOpen = (restaurant) => {
  if (restaurant?.openingTimes?.length < 1) return false;
  const date = new Date();
  const day = date.getDay();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const todaysTimings = restaurant?.openingTimes?.find(
    (o) => o.day === ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'][day]
  );
  if (!todaysTimings) return false;

  // Check all time slots for today's timings
  return todaysTimings.times.some((t) => {
    const startHour = Number(t.startTime[0]);
    const startMinute = Number(t.startTime[1]);
    const endHour = Number(t.endTime[0]);
    const endMinute = Number(t.endTime[1]);

    const startTime = startHour * 60 + startMinute; // Convert to minutes
    const endTime = endHour * 60 + endMinute; // Convert to minutes
    const currentTime = hours * 60 + minutes; // Convert to minutes

    return currentTime >= startTime && currentTime <= endTime;
  });
};

const countDays = (startDate, endDate) => {
  // Get only the date parts (ignoring time) for both startDate and endDate
  const startDateOnly = new Date(startDate.setHours(0, 0, 0, 0));
  const endDateOnly = new Date(endDate.setHours(0, 0, 0, 0));

  // Check if the start date and end date are the same
  const isSameDay = startDateOnly.getTime() === endDateOnly.getTime();

  let daysCount = 0;
  // If they are different, count as 2 days
  if (isSameDay) {
    daysCount = 1;
  } else {
    const differenceInMilliseconds = endDateOnly - startDateOnly;
    daysCount = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24)) + 1; // Add 1 because we want to include both start and end dates
  }
  // Get today's date
  const today = new Date();
  const todayOnly = new Date(today.setHours(0, 0, 0, 0));

  const differenceInMillisecondsFromEnd = endDateOnly - todayOnly;
  const daysLeftFromEnd = Math.floor(
    differenceInMillisecondsFromEnd / (1000 * 60 * 60 * 24)
  );

  return { daysCount, daysLeftFromEnd };
};

const hoursTillMidnight = () => {
  const now = new Date();
  const midnight = new Date();
  midnight.setHours(24, 0, 0, 0);

  return (midnight.getTime() - now.getTime()) / (1000 * 60 * 60);
};

const checkPreorder = (restaurant) => {
  let preOrder = {
    canPreOrder: false,
    timeToOrder: null,
  };

  const date = new Date();
  const day = date.getDay();
  const hours = date.getHours();
  const todaysTimings = restaurant?.openingTimes?.find(
    (o) => o.day === DAYS[day]
  );

  if (restaurant?.deliveryMode?.allowPreOrd) {
    if (todaysTimings.times.length === 0) {
      if (24 - hours <= restaurant.deliveryMode.minHours) {
        const tomorrow = new Date(date);
        tomorrow.setDate(date.getDate() + 2);
        tomorrow.setHours(0, 0, 0, 0);
        preOrder.canPreOrder = true;
        preOrder.canPreOrder = tomorrow;
      }
    }

    const { isHoliday, startTime, endTime } = checkHoliday(restaurant.holidays);
    if (isHoliday) {
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const orderPlaceTime = new Date(endDate);
      orderPlaceTime.setDate(endDate.getDate() + 2);
      orderPlaceTime.setHours(0, 0, 0, 0);
      let { daysCount, daysLeftFromEnd } = countDays(startDate, endDate);
      let tempDaysLeftFromEnd = daysLeftFromEnd + 1;
      if (daysCount >= restaurant.deliveryMode.minDays) {
        if (tempDaysLeftFromEnd <= restaurant.deliveryMode.minDays) {
          preOrder.canPreOrder = true;
          preOrder.timeToOrder = orderPlaceTime;
        }
      } else {
        if (
          daysLeftFromEnd === 0 &&
          hoursTillMidnight() <= restaurant.deliveryMode.minHours
        ) {
          preOrder.canPreOrder = true;
          preOrder.timeToOrder = orderPlaceTime;
        }
      }
    }
  }

  return preOrder;
};

const checkAlwaysPreOrder = (restaurant) => {
  let preOrder = {
    canPreOrder: false,
    timeStartPreOrder: null,
    timeEndPreOrder: null,
  };

  const minDays = restaurant?.deliveryMode?.minDays || 0; // Fallback to 0 if minDays is not provided

  const today = new Date();

  const minDate = new Date(today);
  minDate.setDate(today.getDate() + 1);
  minDate.setHours(0, 0, 0, 0);

  const maxDate = new Date(minDate);
  maxDate.setDate(minDate.getDate() + minDays - 1);
  maxDate.setHours(0, 0, 0, 0);

  // Set preOrder times
  preOrder.timeStartPreOrder = minDate;
  preOrder.timeEndPreOrder = maxDate;

  if (restaurant?.deliveryMode?.alwaysPreOrd) {
    preOrder.canPreOrder = true;
  }

  return preOrder;
};

const getDeliverySlotsForDay = (selectedDate, deliverySlots) => {
  // Get the day of the week from the selected date
  const selectedDay = new Date(selectedDate).getDay();
  const dayName = DAYS[selectedDay]; // e.g., "SAT"

  // Find matching delivery slots
  const matchingSlot = deliverySlots?.find((slot) => {
    if (slot.day.includes('-')) {
      // Handle ranges like "MON-FRI"
      const [startDay, endDay] = slot.day.split('-');
      const startIndex = DAYS.indexOf(startDay);
      const endIndex = DAYS.indexOf(endDay);
      return selectedDay >= startIndex && selectedDay <= endIndex;
    } else {
      // Handle individual days like "SAT", "SUN"
      return slot.day === dayName;
    }
  });

  // Prepare result
  return {
    slots: matchingSlot ? matchingSlot.times : [],
    formattedDay: DAYS_SLOTS[selectedDay],
    matchingDayGroup: matchingSlot ? matchingSlot.day : null,
  };
};

function getDeliverySlotsForDate(dateString, deliverySlots) {
  // Parse the date
  const date = new Date(dateString);

  // Get the day of the week (0 for Sunday, 6 for Saturday)

  const dayIndex = date.getUTCDay();

  // Determine the day category
  const day = DAYSPREFIX[dayIndex];

  // Find the matching delivery slots
  const slot = deliverySlots?.find((slot) => slot.day === day);
  return {
    s: DAYS_SLOTS[dayIndex],
    day: day,
    slots: slot ? slot.times : [],
  };
}

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  )
    .toLocaleDateString('en-GB')
    .replace(/\//g, '-'); // "10-02-2025"; // "10/02/2025"
};

const getCurrentDay = () => {
  const today = new Date();
  const dayIndex = today.getDay();
  if (dayIndex >= 1 && dayIndex <= 5) return 'MON-FRI';
  if (dayIndex === 6) return 'SAT';
  if (dayIndex === 0) return 'SUN';
  return '';
};

const getCurrentDaySlotName = () => {
  const today = new Date();
  const dayIndex = today.getDay();

  return DAYS_SLOTS[dayIndex];
};

const filterSlotsByDay = (restaurantDeliverySlots, day) => {
  const slot = restaurantDeliverySlots?.find((slot) => slot.day === day);
  return slot ? slot.times : [];
};

function checkHoliday(holidays) {
  if (!holidays) return { isHoliday: false, holidayName: null };

  // Get today's date and reset time part (midnight of today)
  const today = new Date();
  const todayTimestamp = today.setHours(0, 0, 0, 0); // Remove time part for comparison

  for (const holiday of holidays) {
    // Convert startTime and endTime to dates, removing time part
    const startTime = new Date(Number(holiday.startTime)).setHours(0, 0, 0, 0);
    const endTime = new Date(Number(holiday.endTime)).setHours(0, 0, 0, 0);

    // Check if today is between the start and end date, or is the same as either of them
    if (todayTimestamp >= startTime && todayTimestamp <= endTime) {
      return { isHoliday: true, holidayName: holiday.name, startTime, endTime };
    }
  }

  return {
    isHoliday: false,
    holidayName: null,
    startTime: null,
    endTime: null,
  };
}

const formatToISO = (date) => {
  if (!date) return null;

  // Adjust for the local timezone offset
  const offsetMinutes = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - offsetMinutes * 60000);

  return adjustedDate.toISOString().replace('Z', '+00:00');
};

const findNextOpenDate = (openingTimes, startDay, date) => {
  for (let i = 1; i <= 7; i++) {
    const nextDayIndex = (startDay + i) % 7;
    const nextTimings = openingTimes.find((o) => o.day === DAYS[nextDayIndex]);

    if (nextTimings && nextTimings.times.length > 0) {
      // Create a future date for the next open day
      const nextOpenDate = new Date(date); // Clone current date to avoid mutation
      nextOpenDate.setDate(date.getDate() + i);
      nextOpenDate.setHours(
        Number(nextTimings.times[0].startTime[0]), // Ensure accessing correct properties
        Number(nextTimings.times[0].startTime[1]),
        0,
        0
      );
      return formatToISO(nextOpenDate);
    }
  }
  return null;
};

function calculateAmount(costType, deliveryRate, distance) {
  return costType === 'fixed'
    ? deliveryRate
    : Math.ceil(distance) * deliveryRate;
}

export {
  isValidEmailAddress,
  cartItemQuantity,
  calculatePrice,
  calculateDistance,
  calculateAmount,
  getDeliverySlotsForDate,
  checkHoliday,
  getDeliverySlotsForDay,
  checkAlwaysPreOrder,
  checkPreorder,
  isOpen,
  getCurrentDay,
  getCurrentDaySlotName,
  filterSlotsByDay,
  formatDate,
  findNextOpenDate,
  calculateGoogleMapsDistance,
};
