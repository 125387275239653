import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function DeliverySlotModal({
  open,
  onClose,
  filteredSlots,
  selectedSlot,
  setSelectedSlot,
  handleSlotSelection,
  handleNext,
  showName,
  pre,
  ...rest
}) {
  const selectedSlotKey = selectedSlot
    ? `${selectedSlot.startTime.join(':')}-${selectedSlot.endTime.join(':')}`
    : '';

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth {...rest}>
      {/* Header */}
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          color: 'black',
        }}
      >
        Select a Delivery Slot
        <IconButton onClick={onClose} color="error">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Content */}
      <DialogContent dividers>
        <Typography
          sx={{ color: 'black', fontWeight: 'bold', marginBottom: '10px' }}
        >
          {showName} {pre?.canPreOrder ? ' ( Pre-order )' : ''}
        </Typography>

        <RadioGroup
          value={selectedSlotKey}
          onChange={(e) => {
            const selected = filteredSlots.find(
              (slot) =>
                `${slot.startTime.join(':')}-${slot.endTime.join(':')}` ===
                e.target.value
            );
            if (selected) {
              setSelectedSlot(selected);
            }
          }}
        >
          {filteredSlots.length > 0 ? (
            filteredSlots.map((slot, index) => {
              const slotKey = `${slot.startTime.join(':')}-${slot.endTime.join(':')}`;
              return (
                <FormControlLabel
                  key={index}
                  value={slotKey}
                  control={<Radio />}
                  label={
                    <Typography component="span" sx={{ color: 'black' }}>
                      {slot.startTime.join(':')} - {slot.endTime.join(':')}
                    </Typography>
                  }
                />
              );
            })
          ) : (
            <Typography color="error" mt={2}>
              No delivery slots available for today.
            </Typography>
          )}
        </RadioGroup>
      </DialogContent>

      {/* Footer Actions */}
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleNext} variant="contained" color="primary">
          Next
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeliverySlotModal;
