import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getDeliverySlotsForDay } from '../../utils/customFunction';

const PreOrderModal = ({
  open,
  onClose,
  pre2,
  deliverySlots,
  handlePreOrder,
  loading = false,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [filteredSlots, setFilteredSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [dayName, setDayName] = useState(null);
  const [deliverySlotDay, setDeliverySlotDay] = useState(null);

  const handleDateChange = (date) => {
    if (!date) return;
    setSelectedDate(date);

    let { slots, formattedDay, matchingDayGroup } = getDeliverySlotsForDay(
      date,
      deliverySlots
    );

    setDeliverySlotDay(matchingDayGroup);
    setDayName(formattedDay);
    setFilteredSlots(slots);
    setSelectedSlot(null);
  };

  const handleSlotSelection = (event) => {
    const selectedValue = event.target.value;
    const slotObject = filteredSlots.find(
      (slot) =>
        `${slot.startTime.join(':')}-${slot.endTime.join(':')}` ===
        selectedValue
    );
    setSelectedSlot(slotObject);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: 500,
          bgcolor: 'white',
          p: 3,
          borderRadius: 2,
          boxShadow: 24,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {/* Header */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" fontWeight="bold">
            Pre-Order
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Date Picker */}
        <Typography variant="h6" fontWeight="bold" mt={2}>
          Select an Available Date
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          sx={{ margin: '20px 0 10px 0' }}
        >
          <Calendar
            onChange={handleDateChange}
            value={selectedDate ? new Date(selectedDate) : null}
            minDate={
              pre2?.timeStartPreOrder
                ? new Date(pre2.timeStartPreOrder)
                : undefined
            }
            maxDate={
              pre2?.timeEndPreOrder ? new Date(pre2.timeEndPreOrder) : undefined
            }
          />
        </Box>

        {/* Time Slots */}
        {filteredSlots?.length > 0 && (
          <>
            <Typography variant="h6" fontWeight="bold" mt={2}>
              {dayName}
            </Typography>
            <RadioGroup
              value={
                selectedSlot
                  ? `${selectedSlot.startTime.join(':')}-${selectedSlot.endTime.join(':')}`
                  : ''
              }
              onChange={handleSlotSelection}
            >
              {filteredSlots?.map((slot, index) => {
                const slotKey = `${slot.startTime.join(':')}-${slot.endTime.join(':')}`;
                return (
                  <FormControlLabel
                    key={index}
                    value={slotKey}
                    control={<Radio />}
                    label={`${slot.startTime.join(':')} - ${slot.endTime.join(':')}`}
                  />
                );
              })}
            </RadioGroup>
          </>
        )}

        {/* Footer Button */}
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 3 }}
          onClick={() => {
            const timezoneOffset = new Date().getTimezoneOffset();
            const adjustedDate = new Date(selectedDate);

            adjustedDate.setMinutes(adjustedDate.getMinutes() - timezoneOffset);

            const formattedDate = adjustedDate
              .toISOString()
              .replace('Z', '+00:00');

            handlePreOrder({
              deliverySlot: {
                day: deliverySlotDay,
                startTime: selectedSlot.startTime,
                endTime: selectedSlot.endTime,
              },
              preOrder: {
                canPreOrder: true,
                timeToOrder: formattedDate,
              },
            });
          }}
          disabled={loading || !selectedSlot}
        >
          {loading ? 'Placing Order...' : 'Place Pre-Order'}
        </Button>
      </Box>
    </Modal>
  );
};

export default PreOrderModal;
